@use "variables" as *;

.container {
  z-index: 2;
  position: relative;
  width: 80px;
  color: white;
  background-color: black;
  border-radius: 10px 0 0 10px;

  .innerContainer {
    width: 80px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media screen and (min-height: 800px) {
      position: sticky;
      top: 0;
    }

    .hamburger {
      height: 60px;
      width: 100%;
      cursor: pointer;
      background-color: transparent;
      border: none;
      outline: none;
      z-index: 3;
    }

    .modeButton {
      width: 90%;
      display: flex;
      align-self: center;
      justify-content: center;
      padding: 10px 0;
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      color: white;
      transition: 0.1s;

      &:hover {
        background-color: #101112;
        border-radius: 10px;
      }
    }

    .active {
      background-color: #191a1c !important;
      border-radius: 10px;
    }

    .menu {
      .expanded {
        position: absolute;
        top: 0;
        left: 0;
        width: 400px;
        display: block;
        background-color: black;
        border-radius: 10px 10px;

        .title {
          padding-left: 30px;
          color: #555555;
        }

        .modes {
          display: flex;
          flex-direction: column;
          gap: 10px;
          list-style: none;
          padding: 0;

          &:first-of-type {
            margin: 70px 0 0 0;
          }

          li {
            display: flex;
            justify-content: center;
          }
        }

        .hamburger {
          text-align: left;
          padding-left: 20px;
        }

        .modeButtonExpanded {
          display: flex;
          align-items: center;
          gap: 25px;
          width: 95%;
          padding: 10px 0 10px 20px;
          background-color: transparent;
          border: none;
          outline: none;
          cursor: pointer;
          color: white;
          font-size: 16px;
          font-weight: bold;
          transition: 0.1s;
        }

        .active {
          border-radius: 10px;
        }
      }
      .collapsed {
        display: none;
      }
    }
  }
}
