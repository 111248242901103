@use "variables" as *;

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: rgba(13, 13, 13, 0.88);
  padding: 20px 0 60px;
  border-radius: 0 10px 10px 0;

  form {
    position: relative;
    margin: 0 100px;

    button {
      position: absolute;
      bottom: 7px;
      right: 15px;
      max-width: 40px;
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
    }
  }

  .header {
    display: flex;
    margin: 0 100px;

    .label {
      color: white;
      display: flex;
      gap: 7px;
      width: 100%;

      input {
        color: white;
        font-size: 16px;
        background-color: transparent;
        border: transparent;
        cursor: pointer;

        &:focus {
          cursor: text;
        }
      }
    }
  }

  .extras {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 0 100px;

    .attachments {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 10px;

      .upload {
        font-size: 14px;
        display: flex;
        align-items: end;
        gap: 5px;
        border: none;
        outline: none;
        cursor: pointer;
        color: white;
        background-color: #191a1c;
        padding: 8px 16px;
        border-radius: 20px;
        opacity: 0.7;

        &:hover {
          opacity: 1;
        }
      }

      .uploaded {
        position: relative;
        width: 150px;
        display: flex;
        padding: 10px;
        background-color: #191a1c;
        border-radius: 15px;

        .uploadedImage {
          max-width: 100%;
          border-radius: 10px;
        }

        .clear {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 25px;
          height: 25px;
          position: absolute;
          top: 0;
          right: 0;
          border-radius: 50%;
          background-color: white;
          border: none;
        }
      }
    }
  }
}
