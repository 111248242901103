.component {
  .arrowUp {
    transform: rotate(180deg);
  }

  .menuButton {
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
    opacity: 0.7;
    color: white;
    align-items: center;
    background-color: #191a1c;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    min-width: 150px;
    user-select: none;

    &:hover {
      opacity: 1;
    }

    img {
      align-self: end;
      transition: transform 0.2s ease-in-out;
    }

    &.open {
      border-radius: 4px 4px 0 0;
    }

    .clearButton {
      opacity: 0.7;
      max-height: 16px;

      &:hover {
        opacity: 1;
      }
    }
  }

  .loadingList {
    color: white;
    text-align: center;
  }

  .menu {
    width: 100%;
    background-color: #191a1c;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    margin-top: 8px;
    max-height: 200px;
    overflow-y: auto;

    .gptContainer {
      display: flex;
      flex-direction: column;
      width: 100%;

      .gptNameContainer {
        display: flex;
        gap: 10px;
      }

      .content {
        opacity: 0.7;
        color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 16px;
        cursor: pointer;
        transition: background-color 0.2s;

        &:hover {
          opacity: 1;
        }

        &.selected {
          opacity: 1;
          font-weight: 500;
        }

        .gptName {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 220px;
        }

        .actionButton {
          background: none;
          border: none;
          cursor: pointer;
          opacity: 0.6;
          transition: opacity 0.2s;

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }

  .last {
    color: white;
    padding: 12px 16px;
    background-color: #191a1c;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    margin-top: 8px;

    .createNew {
      text-align: center;
      font-weight: 500;
    }

    .createMenu {
      h3 {
        font-size: 16px;
        text-align: center;
        font-weight: 500;
      }

      .form {
        position: static;
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin: 0 30px;

        p {
          font-weight: 500;
          margin-bottom: 0px;
        }

        .input,
        .textarea {
          padding: 8px 12px;
          border: 1px solid #ddd;
          border-radius: 4px;
          font-family: inherit;

          &:focus {
            outline: none;
            border-color: #1677ff;
            box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
          }
        }

        .formButtons {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          gap: 10px;
          margin: 8px 0;

          button {
            cursor: pointer;
            padding: 10px;
            border-radius: 4px;
          }

          .cancelButton {
            background-color: white;
            border: 1px solid #ddd;

            &:hover {
              background-color: #f5f5f5;
            }
          }

          .saveButton {
            background-color: #1677ff;
            border: 1px solid #1677ff;
            color: white;

            &:hover {
              background-color: #0958d9;
            }

            &:disabled {
              background-color: #91caff;
              border-color: #91caff;
              cursor: not-allowed;
            }
          }
        }
      }
    }
  }
}
