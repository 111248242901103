.component {
  width: min(90%, 1250px);
  height: 80px;
  align-self: center;
  display: flex;
  justify-content: space-between;

  .leftObjects {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .rightObjects {
    position: relative;
    display: flex;
    align-items: center;
    gap: 20px;

    div {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      h1 {
        color: white;
        font-size: 20px;
        margin: 0px;
      }

      span {
        color: white;

        &:hover {
          cursor: pointer;
          font-weight: 600;
        }
      }
    }

    .languageSwitcher {
      z-index: 2;
      position: absolute;
      top: -5px;
      right: -5px;
      opacity: 0.8;

      &:hover {
        cursor: pointer;
        opacity: 1;
      }

      img {
        border-radius: 1px;
      }
    }

    .languageMenu {
      z-index: 1;
      position: absolute;
      top: -5px;
      right: -5px;
      background-color: black;
      border-radius: 5px;
      padding: 30px 20px 10px;
      display: flex;
      flex-direction: column;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
      width: max-content;
      min-width: 120px;

      button {
        display: flex;
        gap: 10px;
        background-color: transparent;
        border: none;
        padding: 0px;
        margin: 0px;
        font-size: 16px;
        color: white;
        margin: 5px 0px;
        width: 100%;

        &:hover {
          cursor: pointer;
          font-weight: 600;
        }

        img {
          border-radius: 1px;
        }
      }
    }

    img {
      border-radius: 50%;
    }
  }
}

.statuses {
  display: flex;
  flex-direction: column;
  max-width: min(90%, 1250px);
  align-self: center;
  gap: 10px;
  margin-top: -30px;

  .status {
    position: relative;
    background-color: rgba(13, 13, 13, 1);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 10px;

    .info,
    .warning,
    .critical {
      display: flex;
      align-items: center;
      gap: 5px;
      padding: 10px;
      border-radius: 5px;
      text-align: center;
    }

    .info {
      color: lightblue;
    }

    .warning {
      color: yellow;
    }

    .critical {
      color: red;
    }

    .clear {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      position: absolute;
      top: -6px;
      right: -10px;
      border-radius: 50%;
      background-color: white;
      border: none;
    }
  }
}
